@import url('https://fonts.googleapis.com/css2?family=Poppins:ital@0;1&display=swap');
* {
    box-sizing: border-box;
  }
  .intro{
    background-color: black;
  }
  body {
    margin: 0;
  }
h1{
  color:#FFF;
  text-transform: uppercase;
  font-size: 60px;
}
h1 span{
  font-weight: normal;
}
h2{
  color:#FFF;
}
  body {
    background-color: black!important;
  }

  .row {
    display: flex;
    align-content: center;
    justify-content: center;
  }

  .col-left {
    display: flex;
    flex-direction: column;
    align-items: center;
    opacity: 0.9;
  }

  .col-right:hover {
    opacity: 0.5;
    cursor: pointer;
  }

  .fade-in-door {
    animation: fadeIn 5s;
    opacity: 0;
    width:250px;
    animation-fill-mode: forwards;
  }

  .fade-in-door:hover {
    opacity: 0.8;
  }

  .fade-in-text {
    animation: fadeIn 5s;
    opacity: 0;
    animation-delay: 2s;
    animation-fill-mode: forwards;
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }

  .typewriter {
    text-align: center;
    color: #FFF;
  }

  .speak{
    color:#FFF;
  }

  .typewriter h3 {
    font-family: 'Montserrat', sans-serif;
     overflow: hidden;
    /* Ensures the content is not revealed until the animation */
    /*border-right: 0.15em solid orange;*/
    /* The typwriter cursor */
    white-space: nowrap;
    /* Keeps the content on a single line */
    margin: 0 auto;
    /* Gives that scrolling effect as the typing happens */
    letter-spacing: 0em;
    /* Adjust as needed */
    animation: typing 3.5s steps(40, end),
      blink-caret 0.75s step-end infinite;
  }

  /* The typing effect */
  @keyframes typing {
    from {
      width: 0;
    }

    to {
      width: 100%;
    }
  }

  /* The typewriter cursor effect */
  @keyframes blink-caret {

    from,
    to {
      border-color: transparent;
    }

    50% {
      border-color: orange;
    }
  }

  @media (min-width: 576px) {
    .fade-in-door{
      width:100%;
    }
   }